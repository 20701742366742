<template>
  <div v-if="contractor && user" class="container-fluid bg-gray-lighter">
    <div class="md:w-1/2 pt-8 pb-64 px-4 md:px-12">
      <breadcrumbs
        :path="[
          {
            name: $t('add-contractor-user-view.contractors'),
            url: { name: 'Admins' },
          },
          {
            name: contractor.name,
            url: {
              name: 'ContractorDetails',
              params: { contractorId: contractor.id },
            },
          },
          { name: $t('add-contractor-user-view.edit-user') },
        ]"
        class="mt-2 mb-1"
      ></breadcrumbs>
      <contractor-user-form
        id="contractorUserForm"
        class="mb-64"
        :content="user"
        @on-submit="handleSubmission"
      />
    </div>
    <footer-menu>
      <div class="flex justify-center xs:justify-between flex-wrap">
        <div class="flex">
          <btn
            :is-loading="submissionInProgress"
            type="submit"
            form="contractorUserForm"
            class="w-30 mr-1 md:w-48 md:mr-3"
          >
            {{ $t('common.save') }}
          </btn>
        </div>
        <btn theme="none" class="mt-2 xs:mt-0" @click="$router.go(-1)">
          {{ $t('common.cancel') }}
        </btn>
      </div>
    </footer-menu>
  </div>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs.vue';
import Btn from '@/components/Button/Button.vue';
import FooterMenu from '@/components/Footer/FooterMenu.vue';
import ContractorUserForm from '@/views/Dashboard/Contractors/Forms/ContractorUserForm';
import { mapActions, mapState } from 'vuex';
import VueStore from '@/store';

export default {
  name: 'EditContractorUser',
  components: {
    Breadcrumbs,
    Btn,
    FooterMenu,
    ContractorUserForm,
  },
  async beforeRouteEnter(to, from, next) {
    await Promise.all([
      VueStore.dispatch(
        'contractor/fetchContractorDetails',
        to.params.contractorId,
      ),
      VueStore.dispatch('contractor/fetchContractorUser', {
        userId: to.params.userId,
      }),
    ]);
    next();
  },
  data() {
    return {
      submissionInProgress: false,
      submissionEndSuccessfully: false,
    };
  },
  computed: mapState('contractor', {
    contractor: 'details',
    user: 'user',
  }),
  methods: {
    ...mapActions('contractor', ['editContractorUser']),
    async handleSubmission(data) {
      this.submissionEndSuccessfully = false;
      this.submissionInProgress = true;
      try {
        await this.editContractorUser({
          userId: this.user.id,
          data,
        });

        this.submissionEndSuccessfully = true;

        this.$router.push({
          name: 'ContractorUserDetails',
          params: { contractorId: this.contractor.id, userId: this.user.id },
        });

        this.$toast.success(this.$t('add-contractor-user-view.user-saved'));
      } catch (err) {
        this.submissionEndSuccessfully = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.container-fluid {
  @apply min-h-screen;
}
</style>
