<template>
  <ValidationObserver v-slot="{ handleSubmit, reset }">
    <form
      :id="id"
      ref="form"
      class="form py-1"
      @submit.prevent="handleSubmit(pushFormData)"
      @reset.prevent="reset"
    >
      <ValidationProvider v-slot="{ errors }" rules="required">
        <text-input
          v-model="firstName"
          :label="$t('contractor-user-form.first-name')"
          :placeholder="$t('contractor-user-form.first-name-placeholder')"
          :error-message="errors[0]"
          :disabled="isLoading"
          required
          class="mt-6"
        ></text-input>
      </ValidationProvider>
      <ValidationProvider v-slot="{ errors }" rules="required">
        <text-input
          v-model="lastName"
          :label="$t('contractor-user-form.last-name')"
          :placeholder="$t('contractor-user-form.last-name-placeholder')"
          :error-message="errors[0]"
          :disabled="isLoading"
          required
          class="mt-5"
        ></text-input>
      </ValidationProvider>
      <ValidationProvider v-slot="{ errors }" rules="email|required">
        <text-input
          v-model="email"
          :label="$t('contractor-user-form.e-mail')"
          :placeholder="$t('contractor-user-form.e-mail-placeholder')"
          :error-message="errors[0]"
          :disabled="isLoading"
          required
          class="mt-5"
        ></text-input>
      </ValidationProvider>
      <ValidationProvider
        v-slot="{ errors }"
        name="phone"
        rules="phone|required"
      >
        <text-input
          v-model="phone"
          :label="$t('admin-form.phone')"
          :placeholder="$t('admin-form.phone-placeholder')"
          :error-message="errors[0]"
          :disabled="isLoading"
          required
          class="my-4"
        ></text-input>
      </ValidationProvider>
      <select-input
        v-model="brands"
        :label="$t('contractor-user-form.brands')"
        :placeholder="$t('contractor-user-form.brands-placeholder')"
        url="brands"
        :query="brandsQuery"
        option-value-name="id"
        option-label-name="name"
        class="mt-5"
        multiple
        autocomplete
      />
    </form>
  </ValidationObserver>
</template>

<script>
import { TextInput, SelectInput } from '@/components/Inputs';
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate';
import { required, email } from 'vee-validate/dist/rules';
import { mapState } from 'vuex';
import { SUBCONTRACTOR } from '@/store/modules/contractor';
import { getBrandsList } from '@/api/requests/brand';
import { loadAllResults } from '@/api/requests/pagination';
import { mdiPlus } from '@mdi/js';
import { mapId } from '@/util/common';

export default {
  components: {
    TextInput,
    SelectInput,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    content: {
      type: Object,
      default: null,
    },
    clear: Boolean,
    isLoading: Boolean,
  },
  data() {
    return {
      mdiPlus,
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      brands: [],
      subcontractor: false,
      allReportsContact: false,
    };
  },
  computed: {
    ...mapState('client', {
      client: 'details',
    }),
    ...mapState('contractor', {
      contractorBrands: 'brands',
      contractor: 'details',
    }),
    brandIds() {
      return this.brands.map((brand) => brand.id);
    },
    brandsQuery() {
      return {
        contractorId: this.contractor.id,
      };
    },
  },
  watch: {
    clear(val) {
      if (val) this.clearForm();
    },
    content: {
      immediate: true,
      async handler(newContent) {
        if (newContent !== null) {
          const { firstName, lastName, email, phone, role } = newContent;
          this.firstName = firstName;
          this.lastName = lastName;
          this.email = email;
          this.phone = phone;
          this.subcontractor = role === SUBCONTRACTOR;
          this.brands = await loadAllResults(getBrandsList, {
            contractorId: this.contractor.id,
            assignedToContractorUserId: this.content.id,
          });
        }
      },
    },
  },
  created() {
    extend('email', {
      ...email,
      message: this.$t('errors.error-email'),
    });

    extend('required', {
      ...required,
      message: this.$t('errors.error-empty'),
    });

    extend('phone', {
      validate(value) {
        return value.match(/(^(\+48)?(\s)?\d{3}(\s)?\d{3}(\s)?\d{3}$)/);
      },
      message: this.$t('errors.error-phone'),
    });
  },
  methods: {
    pushFormData() {
      this.$emit('on-submit', {
        contractorId: !this.content ? this.contractor.id : undefined,
        firstName: this.firstName,
        lastName: this.lastName,
        email: this.email,
        phone: this.phone,
        brandIds: mapId(this.brands),
        internalRole: '',
      });
    },
    clearForm() {
      this.firstName = '';
      this.lastName = '';
      this.email = '';
      this.phone = '';
      this.brands = [];
      this.subcontractor = true;
      this.allReportsContact = false;

      this.$nextTick(() => {
        this.$refs.form.reset();
      });
    },
    handleDelete(element) {
      element.isDeleted = !element.isDeleted;
    },
  },
};
</script>
