var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var reset = ref.reset;
return [_c('form',{ref:"form",staticClass:"form py-1",attrs:{"id":_vm.id},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.pushFormData)},"reset":function($event){$event.preventDefault();return reset.apply(null, arguments)}}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('text-input',{staticClass:"mt-6",attrs:{"label":_vm.$t('contractor-user-form.first-name'),"placeholder":_vm.$t('contractor-user-form.first-name-placeholder'),"error-message":errors[0],"disabled":_vm.isLoading,"required":""},model:{value:(_vm.firstName),callback:function ($$v) {_vm.firstName=$$v},expression:"firstName"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('text-input',{staticClass:"mt-5",attrs:{"label":_vm.$t('contractor-user-form.last-name'),"placeholder":_vm.$t('contractor-user-form.last-name-placeholder'),"error-message":errors[0],"disabled":_vm.isLoading,"required":""},model:{value:(_vm.lastName),callback:function ($$v) {_vm.lastName=$$v},expression:"lastName"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"email|required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('text-input',{staticClass:"mt-5",attrs:{"label":_vm.$t('contractor-user-form.e-mail'),"placeholder":_vm.$t('contractor-user-form.e-mail-placeholder'),"error-message":errors[0],"disabled":_vm.isLoading,"required":""},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"phone","rules":"phone|required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('text-input',{staticClass:"my-4",attrs:{"label":_vm.$t('admin-form.phone'),"placeholder":_vm.$t('admin-form.phone-placeholder'),"error-message":errors[0],"disabled":_vm.isLoading,"required":""},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}})]}}],null,true)}),_c('select-input',{staticClass:"mt-5",attrs:{"label":_vm.$t('contractor-user-form.brands'),"placeholder":_vm.$t('contractor-user-form.brands-placeholder'),"url":"brands","query":_vm.brandsQuery,"option-value-name":"id","option-label-name":"name","multiple":"","autocomplete":""},model:{value:(_vm.brands),callback:function ($$v) {_vm.brands=$$v},expression:"brands"}})],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }